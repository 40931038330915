import {Layout} from "../components/Layout";
import React from 'react';
import {ContactLayout} from "../components/ContactLayout";
import {BlockTitle} from "../components/BlockTitle";
import {Typography} from "../components/Typography";
import * as styles from './contact.module.scss';
// import {ContactPerson} from "../components/ContactPerson";
import {ContactInfo} from "../components/ContactInfo";
import {PhoneNumberLink} from "../components/PhoneNumberLink";
import {EmailLink} from "../components/EmailLink";
import {graphql} from "gatsby";
import {MarkdownContent} from "../components/MarkdownContent";
import {ContactForm} from "../components/ContactForm";
import {useIntl} from 'gatsby-plugin-react-intl';

// function ContactPersonBlock({title, children}) {
//     return <section className={styles.contactPerson}>
//         <h1 className={styles.contactPersonTitle}>{title}</h1>
//         {children}
//     </section>
// }

export const query = graphql`query ContactPage($locale: String!) {
  contact: contentfulContactPage(
    slug: {eq: "contact"}
    node_locale: {eq: $locale}
  ) {
    title
    intro {
      childMarkdownRemark {
        html
      }
    }
    content {
      childMarkdownRemark {
        html
      }
    }
    seo {
      ...Seo
    }
  }
}

fragment ContactInfo on ContentfulContactPerson {
  name
  position
  phone
  email
  avatar {
    localFile {
      childImageSharp {
        gatsbyImageData(
          height: 160
          width: 110
          placeholder: BLURRED
          transformOptions: {cropFocus: CENTER}
          layout: FIXED
        )
      }
    }
  }
}
`

export default function ContactPage({data, pageContext}) {
    const intl = useIntl();
    return <Layout contentWrapperComponent={ContactLayout}
                   withFooter={false}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   enableRecaptchaBadge={true}
                   jobsCount={pageContext.jobsCount}
                   seo={data.contact.seo}
                   title={intl.formatMessage({id: 'menu.contact'})}
                   services={pageContext.services}
                   lang={pageContext.lang}>
        <ContactLayout.Content>
            <BlockTitle>Contact Us</BlockTitle>

            <div className={styles.mainContent}>
                <Typography emphasizeFirstParagraph className={styles.description}>
                    <MarkdownContent component="p" content={data.contact.intro}/>
                    <MarkdownContent component="p" content={data.contact.content}/>
                </Typography>
                <ContactForm className={styles.contactForm}/>
            </div>

            {/*<div className={styles.contactPeople}>*/}
            {/*    <ContactPersonBlock title="Twój Polski doradca">*/}
            {/*        <ContactPerson*/}
            {/*            name={data.contact.polishContactPerson.name}*/}
            {/*            position={data.contact.polishContactPerson.position}*/}
            {/*            image={data.contact.polishContactPerson.avatar.localFile.childImageSharp}*/}
            {/*            email={data.contact.polishContactPerson.email}*/}
            {/*            phone={data.contact.polishContactPerson.phone}*/}
            {/*        />*/}
            {/*    </ContactPersonBlock>*/}
            {/*    <ContactPersonBlock title="Your English advisor">*/}
            {/*        <ContactPerson*/}
            {/*            name={data.contact.englishContactPerson.name}*/}
            {/*            position={data.contact.englishContactPerson.position}*/}
            {/*            image={data.contact.englishContactPerson.avatar.localFile.childImageSharp}*/}
            {/*            email={data.contact.englishContactPerson.email}*/}
            {/*            phone={data.contact.englishContactPerson.phone}*/}
            {/*        />*/}
            {/*    </ContactPersonBlock>*/}
            {/*</div>*/}

        </ContactLayout.Content>
        <ContactLayout.Aside className={styles.aside}>
            <ContactInfo title={intl.formatMessage({id: 'contact.office'})}>
                <div>
                    {pageContext.companyData.name}<br/>
                    {pageContext.companyData.street}<br/>
                    {pageContext.companyData.postalCode} {pageContext.companyData.city}, {pageContext.companyData.country}
                </div>

                <a href="https://g.page/ggs-it-consulting?share"
                   className={styles.contactLink}
                   rel="nofollow noopener noreferrer"
                   target="_blank">
                    {intl.formatMessage({id: 'contact.googleMapsCTA'})}
                </a>
            </ContactInfo>
            <ContactInfo title={intl.formatMessage({id: 'contact.phones'})}>
                {pageContext.companyData.phones.map((x, i) => {
                    return <PhoneNumberLink className={styles.contactLink}
                                            number={x}
                                            key={i}
                    />
                })}
            </ContactInfo>
            <ContactInfo title={intl.formatMessage({id: 'contact.emails'})}>
                {pageContext.companyData.emails.map((x, i) => {
                    return <EmailLink className={styles.contactLink}
                                      email={x}
                                      key={i}/>;
                })}
            </ContactInfo>
        </ContactLayout.Aside>
    </Layout>
}
