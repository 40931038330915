import React, {useEffect} from 'react';
import * as styles from './ContactForm.module.scss';
import {TextInput} from "../TextInput";
import classNames from 'classnames';
import {Button} from "../Button";
import {Checkbox} from "../Checkbox";
import {FormField} from "../FormField";
import {FormButtons} from "../FormButtons";
import {initRecaptcha} from "../../functions/initRecaptcha";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';
import {useIntl} from 'gatsby-plugin-react-intl';
import * as yup from 'yup';
import {sendContactForm} from "../../functions/sendContactForm";
import {LangLink} from "../LangLink";
import {componentRef} from "../../functions/componentRef";

const schema = yup.object()
    .shape({
        name: yup.string()
            .required('error.required')
            .trim(),
        email: yup.string()
            .required('error.required')
            .email('error.email.invalid')
            .trim(),
        message: yup.string()
            .required('error.required')
            .min(10, 'error.message.tooShort')
            .trim(),
        agreement: yup.bool()
            .test('test', 'error.agreement.required', value => value)
    });


export function ContactForm({
                                className,
                                nameLabel,
                                emailLabel,
                                messageLabel,
                                ...otherProps
                            }) {
    useEffect(() => {
        initRecaptcha();
    }, []);

    const intl = useIntl();

    const finalNameLabel = nameLabel ?? intl.formatMessage({id: 'contact.form.fullName'});
    const finalEmailLabel = emailLabel ?? intl.formatMessage({id: 'contact.form.email'});
    const finalMessageLabel = messageLabel ?? intl.formatMessage({id: 'contact.form.message'});

    function translateError(error) {
        if (error) {
            return intl.formatMessage({id: error})
        }
    }

    const {register, handleSubmit, control, errors, formState, reset} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onBlur',
        mode: 'onBlur',
        defaultValues: {
            email: '',
            message: '',
            agreement: false,
            name: ''
        }
    });

    const onSubmit = (values) => {
        return new Promise((resolve, reject) => {
            global.grecaptcha.ready(function () {
                global.grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'contactForm'})
                    .then((token) => {
                        return sendContactForm({
                            captchaToken: token,
                            name: values.name,
                            email: values.email,
                            message: values.message
                        });
                    })
                    .then(() => {
                        reset();
                        toast.success(intl.formatMessage({id: 'contact.form.successMessage'}), {
                            autoClose: 10000,
                            position: "top-center"
                        });
                    })
                    .catch(reject)
            });
        })
    };

    return <form className={classNames(styles.root, className)}
                 {...otherProps}
                 {...componentRef('contact-form')}
                 onSubmit={handleSubmit(onSubmit)}
    >
        <FormField>
            <Controller
                control={control}
                name="name"
                render={(props) => {
                    return <TextInput {...props}
                                      label={finalNameLabel}
                                      disabled={formState.isSubmitting}
                                      error={translateError(errors?.name?.message)}
                    />
                }}
            />
        </FormField>
        <FormField>
            <Controller
                control={control}
                name="email"
                render={(props) => {
                    return <TextInput {...props}
                                      type="email"
                                      label={finalEmailLabel}
                                      disabled={formState.isSubmitting}
                                      error={translateError(errors?.email?.message)}
                    />;
                }}
            />

        </FormField>
        <FormField>
            <Controller
                control={control}
                name="message"
                render={(props) => {
                    return <TextInput
                        {...props}
                        multiLine
                        className={styles.message}
                        error={translateError(errors?.message?.message)}
                        disabled={formState.isSubmitting}
                        label={finalMessageLabel}
                    />
                }}
            />
        </FormField>
        <FormField>
            <Checkbox name="agreement"
                      {...register('agreement')}
                      disabled={formState.isSubmitting}
                      error={translateError(errors?.agreement?.message)}>
                {intl.formatMessage({
                    id: 'contact.form.agreement.label'
                }, {
                    link: <LangLink to="/privacy">{intl.formatMessage({id: 'contact.form.agreement.policy'})}</LangLink>
                })}
            </Checkbox>
        </FormField>
        <FormButtons>
            <Button component="button"
                    type="submit"
                    disabled={formState.isSubmitting}>
                {formState.isSubmitting ? intl.formatMessage({id: 'contact.form.sending'}) : intl.formatMessage({id: 'contact.form.submit'})}
            </Button>
        </FormButtons>
    </form>
}
