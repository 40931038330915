import React from 'react';
import * as styles from './ContactLayout.module.scss';
import classNames from 'classnames';
import {PageMainColumnWrapper} from "../PageMainColumnWrapper";
import {componentRef} from "../../functions/componentRef";

export function ContactLayout({children, className, ...otherProps}) {
    return <div className={classNames(className, styles.root)} {...componentRef('contact-layout')}>
        <PageMainColumnWrapper className={styles.wrapper}>
            {children}
        </PageMainColumnWrapper>
    </div>
}

ContactLayout.Content = ({className, ...otherProps}) => {
    return <div className={classNames(className, styles.content)} {...otherProps} />;
}

ContactLayout.Aside = ({className, ...otherProps}) => {
    return <aside className={classNames(className, styles.aside)} {...otherProps}/>;
}