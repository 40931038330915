import {sendAnalyticsEvent} from "./sendAnalyticsEvent";

export function sendContactForm(data) {
    sendAnalyticsEvent('form.contact.sent');
    global.fetch('https://1c02prvgo6.execute-api.eu-central-1.amazonaws.com/prod/contact', {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify(data)
    })
        .then(resp => {
            if (resp.status === 500) {
                throw new Error('Internal server error');
            }
            if (resp.status === 200) {
                return;
            }
            return resp.json();
        });
}
