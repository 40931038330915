import React from 'react';
import * as styles from './ContactInfo.module.scss';
import classNames from 'classnames';
import {componentRef} from "../../functions/componentRef";

export function ContactInfo({title, className, children, ...otherProps}) {
    return <section className={classNames(className, styles.root)} {...otherProps} {...componentRef('contact-info')}>
        <h1 className={styles.title}>{title}</h1>
        {children}
    </section>
}